export const usePreviousRoute = defineStore('navigation', () => {
  const previousRoute = ref<string | null>(null)

  const setPreviousRoute = (route: string | null) => {
    previousRoute.value = route
  }

  return {
    previousRoute,
    setPreviousRoute,
  }
})
